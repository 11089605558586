<template>
  <div>
    <!-- 资讯推荐模块 -->
    <swiper :imgList="imgList"></swiper>
    <div class="news">
      <div class="news_box">
        <!-- 图片信息 -->
        <div class="pic">
          <img :src="newsImg" alt="" />
        </div>
        <!-- 资讯内容 -->
        <div class="desc_message">
          <h1>青春无悔</h1>
          <span>
            “青春无悔”项目是一项结合创造性和启发性的青少年工作坊，集健康教育、关系教育和品格教育于一体的有特色的又有果效的课程。
          </span>
          <div class="desc_button">
            了解更多
            <i class="iconfont icon-youjiantou1 desc_icon"></i>
          </div>
        </div>
      </div>
      <!-- 第二个资讯模块 -->
      <div class="news_box" style="background-color: #fff; height: 350px">
        <!-- 图片信息 -->
        <div class="pic">
          <img
            :src="adventureInOdyssey"
            alt=""
            style="width: 100%; height: 80%"
          />
        </div>
        <!-- 资讯内容 -->
        <div class="desc_message" style="background-color: #fff">
          <h1>Adventures in Odyssey</h1>
          <span>
            是个获奖的播音剧集，为8-12孩童精心制作同时也适合全家人一起听赏。
            每部长25分钟左右，非常合适在车上播放。剧情中教导永恒真理并带来应用于生活中之圣经原则，
            与全篇充满乐趣、信心及想像力相互平衡。故事中角色生动，令人忘形融入其中。
            特别能教导孩童加深与耶稣的关系为其重点。
          </span>
          <div class="desc_button_AIOC">
            Adventures in Odyssey Club
            <i class="iconfont icon-youjiantou1 desc_icon"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 音频模块 -->
    <div class="ablePeopleVideo">
      <div class="title">良才家庭时刻</div>
      <div class="content">
        <div class="secondTitle">2022-01-27 重建家庭之夜</div>
        <audio
          src="audio/NEXT%20TO%20YOU.mp3"
          controls="controls"
          style="width: 900px; margin-bottom: 50px"
        ></audio>
        <div class="main_content">
          是不是觉得和你孩子的关系疏远了呢？或许是时候介入他们忙碌的时间表，预留一些家庭时间了。<br />
          <br />
          由于现在的孩子们实在是有太多的活动需要参加了，导致家庭成员之间的关旭变得越来越疏远。孩子们忙于运动项目、艺术课、芭蕾课、还有许多其他额外的活动，这让很多家庭都不记得上一次他们一起坐下来吃饭是什么时候了。
          <br />
          但是家庭时间对孩子成长是非常重要的。并且，作为父母，我们有责任把这个安排在时间表里。为什么不在每周选一个固定时间作为家庭之夜呢？你们或许可以一起出去吃个晚饭，确保远离电话和其他的打扰，把这段时间用来放松、同时也来重建彼此之间的关系；或者可以一起去爬山或者野餐；又或者可以在后院摆好椅子一起看星星。不管付什么代价，总之就是要让全家人在一起并且保持彼此之间良好的关系。
          <br />
          但是，如果你不把这排进你们的时间表，这些永远都不会发生。 <br />
          今天就把家庭之夜放进你的时间表吧！
        </div>
        <div class="upload_btn">
          右键下载
          <i class="iconfont icon-youjiantou1 desc_icon"></i>
        </div>
      </div>
    </div>
    <!-- 良才最新文章 -->
    <div class="essay">
      <div class="title">良才最新文章</div>
      <div class="essay_items">
        <div
          class="essay_item"
          v-for="(item, index) in essay_item_pic"
          :key="index"
        >
          <div class="item_pic">
            <img :src="item.src" alt="" />
          </div>
          <div class="item_title">
            {{ item.title }}
          </div>
          <div class="item_content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <!-- 最新良才杂志 -->
    <div class="magazine">
      <h3>最新的爱家杂事</h3>
      <div class="content">
        <div class="content_desc">
          <div class="content_desc_title">2021 家庭特刊</div>
          <h1>教导孩子健康的性</h1>
          <span
            >黄金原则教导我们如果我们想要别人如何对待我们，我们也要同样对待别人。可惜的是，我们意识到通常我们不能将这个原则有智慧地应用到育儿方面。</span
          >
          <div class="continue_read">继续阅读</div>
        </div>
        <div class="content_pic">
          <img :src="amgazineImg" alt="" />
        </div>
      </div>
    </div>
    <!-- 用户注册 -->
    <div class="user_regist">
      <div class="user_regist_title">订阅良才 Newsletter</div>
      <div class="user_regist_content">
        <input type="text" placeholder="姓名" />
        <input type="text" placeholder="电子邮箱" />
        <input type="text" placeholder="国家" />
        <div class="user_regist_btn">
          <div class="btn">订阅</div>
        </div>
      </div>
    </div>
    <!-- 底部微信号 -->
    <div class="wx_bottom">
      <div class="wx_bottom_content"></div>
    </div>
  </div>
</template>
<script>
import swiper from "../components/swiper.vue";
export default {
  components: {
    swiper,
  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          img: "https://image.fulllinkai.com/202204/06/88506400c57b5aab894783e92be3e702.jpeg",
          desc: "家庭和睦,迎接晨曦共怀希望",
          desc_btn: "婚姻家庭 | 家庭共同体",
        },
        {
          id: 2,
          img: "https://image.fulllinkai.com/202204/06/cfb7a909d768acbcadfbe12f4867d7f7.jpeg",
          desc: "指引他们,为他们的未来引路",
          desc_btn: "热门话题 | 青春的迷茫",
        },
        {
          id: 3,
          img: "https://image.fulllinkai.com/202204/06/81008dad113d11a79ff68a35a3217e11.jpeg",
          desc: "有太多的父母将孩子丢给了学校",
          desc_btn: "亲子教育 | 老师需要您的帮助",
        },
      ],
      newsImg:
        "https://image.fulllinkai.com/202204/06/66859cb04955b2cc8b3f3ef8b87bdcb3.jpeg", //第一张资讯的图片
      adventureInOdyssey:
        "https://image.fulllinkai.com/202204/06/e59f63907a263607c98e749f3fd07cf4.png", //ATIO 咨询从上到下的第二张图片,
      essay_item_pic: [
        {
          title: "妈妈不得不说",
          content:
            "你是我的财产，我的孩子，我的事业；我要等到你得到幸福的时候，我才能取得我的幸福。",
          src: "https://image.fulllinkai.com/202204/06/760279ebf5dfbb1557dd05b184718aab.jpeg",
        },
        {
          title: "走出暴力阴影",
          content:
            "她可以付出爱，也可以享受爱；她会因不断成长而更成熟，能与人彼此照顾；能自由表达情感，能找到健全的亲密关系。",
          src: "https://image.fulllinkai.com/202204/06/769bf2a935f0d432575df21e9a425ddf.jpeg",
        },
        {
          title: "跨文化婚姻辅导",
          content:
            "首先，他们强调了祷告的重要性——恳求上帝帮助他们优先考虑上帝的心意而不是他们自己的文化作用。",
          src: "https://image.fulllinkai.com/202204/06/6e411baa6af14ac64c43bb1d3bcc96fc.jpeg",
        },
      ],
      amgazineImg:
        "https://image.fulllinkai.com/202204/06/a0c5e0878df9109d65d79eb709eba02b.jpeg",
    };
  },
  created() {},
  methods: {},
};
</script>
<style  lang='less' scoped>
.news {
  width: 100%;
  .news_box {
    display: flex;
    margin: 0 auto;
    width: 1200px;
    height: 320px;
    padding-top: 15px;
    background-color: #f5f6f7;
    .pic {
      width: 570px;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .desc_message {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      width: 570px;
      background-color: #f5f6f7;
      h1 {
        padding: 10px;
      }
      span {
        width: 373px;
        padding: 20px;
        display: inline-block;
        text-align: left;
      }
      .desc_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 50px;
        margin: 20px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        font-weight: 700 !important;
        letter-spacing: 3px;
        color: #fff;
        cursor: pointer;
        background-color: #7ebec5;
        border-radius: 5px;
        transition: all 0.3s ease;
      }

      .desc_button:hover .desc_icon {
        height: 30px;
        margin-left: 10px;
        margin-top: -20px;
      }
      .desc_button:hover {
        width: 150px;
      }
      .desc_icon {
        height: 0;
        transition: all 0.3s ease;
        overflow: hidden;
        margin-right: -10px;
        font-size: 12px;
        line-height: 50px;
      }
      .desc_button_AIOC {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 50px;
        margin: 20px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        font-weight: 700 !important;
        color: #fff;
        cursor: pointer;
        background-color: #7ebec5;
        border-radius: 5px;
        transition: all 0.3s ease;
      }
      .desc_button_AIOC:hover .desc_icon {
        height: 30px;
        margin-left: 10px;
        margin-top: -20px;
      }
      .desc_button_AIOC:hover {
        width: 330px;
      }
    }
  }
}
.ablePeopleVideo {
  height: 800px;
  background-color: pink;
  background: url("https://focf.org/sc/wp-content/uploads/sites/5/2021/09/pexels-photo-164960.jpeg")
    no-repeat center bottom fixed;
  background-size: 100% 80%;
  .title {
    padding-top: 40px;
    height: 100px;
    color: #fff;
    font-weight: 550;
    font-size: 30px;
  }
  .content {
    margin: 0 auto;
    height: 560px;
    width: 1180px;
    background-color: #f5f6f7;
    .secondTitle {
      padding-top: 20px;
      margin-bottom: 40px;
      font-weight: 550;
      font-size: 30px;
    }
    .main_content {
      width: 900px;
      margin: 0 auto;
      text-align: left;
      font-size: 18px;
      color: #6a6666;
    }
    .upload_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 40px;
      width: 120px;
      height: 47px;
      line-height: 50px;
      font-size: 18px;
      color: #5499c7;
      border: 2px solid #5499c7;
      cursor: pointer;
      transition: all 0.3s ease;
    }
    .upload_btn:hover .desc_icon {
      height: 30px;
      margin-left: 10px;
      margin-top: -20px;
    }
    .upload_btn:hover {
      width: 150px;
    }
    .desc_icon {
      height: 0;
      transition: all 0.3s ease;
      overflow: hidden;
      margin-right: -10px;
      font-size: 12px;
      line-height: 50px;
    }
  }
}
.essay {
  height: 600px;
  .title {
    padding-top: 20px;
    font-size: 30px;
    font-weight: 550;
  }
  .essay_items {
    display: flex;
    margin: 0 100px;
    .essay_item {
      flex: 1;
      margin: 40px;
      .item_title {
        text-align: left;
        padding-left: 15px;
        font-weight: 550;
        margin-top: 20px;
        font-size: 20px;
      }
      .item_content {
        padding-left: 15px;
        font-size: 14px;
        color: #66667d;
        text-align: left;
      }
      .item_pic {
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
}
.magazine {
  padding-top: 80px;
  height: 600px;
  color: #f1f4f5;
  font-size: 30px;
  font-weight: 550;
  background-color: #7ebec5;
  .content {
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
    width: 1200px;
    height: 400px;
    background-color: #fff;
    .content_desc {
      width: 600px;
      box-sizing: border-box;
      padding: 50px;
      display: flex;
      flex-direction: column;
      h1 {
        margin-top: 20px;
        text-align: left;
        font-size: 26px;
        color: #3a3a3a;
      }
      span {
        margin-top: 20px;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        color: #666;
      }
      .continue_read {
        position: relative;
        margin-top: 20px;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        color: #257d94;
        cursor: pointer;
      }
    }
    .content_desc_title {
      color: #fff;
      width: 105px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      background-color: #000;
    }
    .content_pic {
      width: 600px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        opacity: 0.7;
        transition: all 0.3s ease;
      }
      img:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
}
.user_regist {
  display: flex;
  flex-direction: column;
  background-color: #5499c7;
  height: 250px;
  .user_regist_title {
    padding: 30px 0;
    margin: 0 auto;
    width: 1200px;
    height: 50px;
    color: #fff;
    text-align: left;
    font-size: 30px;
    font-weight: 550;
  }
  .user_regist_content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 20px;
    justify-content: space-between;
    width: 1200px;
    height: 50px;
    input {
      padding: 0 10px;
      outline: none;
      border: none;
      height: 100%;
      width: 350px;
    }
  }
  .user_regist_btn {
    margin: 0 auto;
    width: 1200px;
    height: 100px;
    display: flex;
    justify-content: flex-end;
    .btn {
      margin-top: 20px;
      width: 100px;
      height: 50px;
      border-radius: 10px;
      font-size: 25px;
      line-height: 50px;
      background-color: #fff;
    }
    .btn:hover {
      background-color: #5499c7;
      cursor: pointer;
    }
  }
}
.wx_bottom {
  background-color: #21618c;
  height: 100px;
}
.wx_bottom_content {
  margin: 0 auto;
  width: 1200px;
  text-align: left;
  line-height: 100px;
  color: #fff;
}
@media screen and (max-width: 1140px) {
  .user_regist {
    display: flex;
    // align-items: center;
    height: 500px !important;
    align-items: center;
    width: 100vw !important;
    .user_regist_title {
      width: auto !important;
    }
    .user_regist_content {
      display: block !important;
      width: 80vw !important;
      input {
        width: 60vw !important;
        margin: 20px 20px 0 20px !important;
      }
    }
    .user_regist_btn {
      width: auto !important;
      justify-content: flex-start !important;
    }
    .btn {
      margin: 30px auto !important;
    }
  }
  // 杂志模块
  .magazine {
    height: auto !important;
    .content {
      flex-direction: column-reverse !important;
      margin-top: 30px;
      padding-top: 50px;
      height: auto !important;
      .content_pic {
        height: 200px;
        width: 100vw !important;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 80vw !important;
          height: auto !important;
        }
      }
      .content_desc {
        padding: 50px !important;
        width: 100vw !important;
        height: auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  // 散文模块
  .essay {
    height: auto !important;
    .essay_items {
      flex-wrap: wrap;
      margin: 0 50px !important;
      .essay_item {
        flex: auto !important;
      }
    }
  }
  // 音频模块
  .ablePeopleVideo {
    height: auto !important;
    padding: 0 10vw;

    padding-bottom: 10vw;
    background-size: 100% 100% !important;
    .content {
      height: auto !important;
      width: auto !important;
      audio {
        width: 60vw !important;
      }
      .main_content {
        // height: 90vw !important;
        width: 60vw !important;
      }
      .secondTitle {
        margin: 0 auto;
        width: 80vw;
        font-size: 20px !important;
      }
    }
  }
  // 咨询模块
  .news {
    width: 100% !important;
    height: auto !important;
    .news_box {
      flex-direction: column;
      height: auto !important;
      width: 80vw !important;

      .pic {
        margin: 0 auto;
        width: 80vw !important;
      }
      .desc_message {
        padding: 0 !important;
        width: 80vw !important;
        span {
          width: 50vw !important;
        }
      }
      .desc_message:nth-child(2) {
        padding: 0 !important;
        width: 80vw !important;
        span {
          width: 70vw !important;
        }
      }
      .desc_message h1 {
        width: 100vw;
        font-size: 26px;
      }
    }
  }
}
</style>
